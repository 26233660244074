import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useAuth } from "../../Auth";
import ReactGA from "react-ga4";

const AccountInfoEl = styled.section`
  display: flex;
  justify-content: center;
  background: #f1f1f1;
  padding: 34px 20px;

  .account-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 726px;

    .account-greeting {
      border-bottom: solid 1px #cccccc;
      padding-bottom: 17px;
      margin-bottom: 16px;

      h1 {
        color: #0c66a5;
        font-weight: 700;
        letter-spacing: -0.84px;
        font-size: 28px;
        line-height: 33px;
      }
    }

    .account-details {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .name-title-employer {
        margin-right: auto;

        @media (max-width: 1100px) {
          margin-bottom: auto;
        }

        h2 {
          font-size: 18px;
          line-height: 21px;
          font-weight: 700;
          margin-bottom: 4px;
        }

        p {
          font-size: 16px;
          line-height: 19px;
          font-weight: 500;

          &:not(:last-child) {
            margin-bottom: 4px;
          }
        }
      }

      .membership-level-id {
        display: flex;

        .membership-label {
          font-size: 12px;
          line-height: 14px;
          color: #607588;
          font-weight: 500;
        }

        .membership-value {
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.48px;
          color: #000000;
          font-weight: 700;
        }

        .membership-level {
          margin-right: 105px;
        }

        @media (max-width: 1100px) {
          flex-direction: column;
          margin-top: 19px;
          margin-right: 26px;

          .membership-level {
            margin-right: 0;
            margin-bottom: 17px;
          }
        }

        @media (max-width: 767px) {
          flex-direction: row;

          .membership-level {
            margin-right: 40px;
          }
        }
      }
    }
  }

  .account-links {
    max-width: 244px;
    width: 100%;

    @media (max-width: 767px) {
      max-width: 100%;
      margin-top: 32px;
    }

    ul {
      list-style: none;

      li {
        border-bottom: solid 1px #cccccc;
        font-weight: 700;
        &:not(:last-child) {
          margin-bottom: 10px;
        }

        padding-bottom: 8px;
        /* padding-right: 20px; */
        /* width: 100%; */

        a {
          color: #2D66AC;
          text-decoration: none;
          font-size: 14px;
          line-height: 22px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight:700!important;
          img {
            width: 7px;
            height: 13px;
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  background: #fff;
  box-shadow: 0px 3px 6px #0000001f;
  border-radius: 12px;
  max-width: 1280px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 34px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const AccountInfo = () => {
  const { cmpData, acgiURL } = useAuth();
  const { account_info } = cmpData;

  const iconCaretRight = require("../../assets/images/icn-arrow-profile.png");
  function isSupplier(){
    if(cmpData.account_info.membershipLevel === "Premier Supplier"){
      return true;
    }else  if(cmpData.account_info.membershipLevel === "Supporting Supplier"){
      return true;
    }else  if(cmpData.account_info.membershipLevel === "Supplier"){
      return true;
    }else{
      return false;
    }
  }
  return (
    <>
      {account_info && (
        <AccountInfoEl>
          <Container>
            <div className="account-info">
              {account_info.firstName && (
                <div className="account-greeting">
                  <h1>Hi, {account_info.firstName}.</h1>
                </div>
              )}

              <div className="account-details">
                <div className="name-title-employer">
                  {account_info.fullName && <h2>{account_info.fullName}</h2>}
                  {account_info.position && <p>{account_info.position}</p>}

                  {account_info.employer && (
                    <p>
                      <em>{account_info.employer}</em>
                    </p>
                  )}
                </div>

                <div className="membership-level-id">
                  <div className="membership-level">
                    <p className="membership-label">Membership Level</p>
                    <p className="membership-value">
                      {account_info.membershipLevel
                        ? account_info.membershipLevel.split("MVP ").pop()
                        : "N/A"}
                    </p>
                  </div>

                  <div className="membership-id">
                    <p className="membership-label">Member ID</p>
                    <p className="membership-value">
                      {account_info.memberId ? account_info.memberId : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="account-links">
              <ul>
                <li>
                  <a
                    href={acgiURL + "/f?p=STDSSA%3A1000"}
                    onClick={() => {
                      if (window.location.host == "mycues.cues.org") {
                        ReactGA.event({
                          category: "Account",
                          action: "User clicked 'Manage my account'",
                          transport: "xhr",
                        });
                      }
                    }}
                  >
                    Manage My Account <img src={iconCaretRight} alt="Arrow" />
                  </a>
                </li>
                {!isSupplier() &&(
                <li>
                <a
                    href="https://login.cues.org/SAML/InitiateSso?partnerName=urn%3Aamazon%3Awebservices" target="_blank">
                    CUES Activity Summary <img src={iconCaretRight} alt="Arrow"  />
                  </a>
                  {/* <Link to="https://logindev.cues.org/SAML/InitiateSso?partnerName=urn%3Aamazon%3Awebservices">CUES Activity Summary</Link> */}
                </li>
                )}
              </ul>
            </div>
          </Container>
        </AccountInfoEl>
      )}
    </>
  );
};

export default AccountInfo;
