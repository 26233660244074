import React, { useEffect } from 'react';
import Layout from "./Layout"
import AccountInfo from './routes/AccountInfo/AccountInfo';
import Events from './routes/Events/Events';
import './App.css';
import CUManagementSection from './routes/CU-Managment/CU-ManagmentSection';
import LoadingScreen from "./LoadingScreen";
import { useAuth } from "./Auth";
import "./App.css";
import CuesNet from './routes/CuesNet/CuesNet';
import Benefits from './routes/Benefits/Benefits';
import Learning from './routes/Learning/Learning';
import DebugInfo from './routes/DebugInfo/DebugInfo';
import WelcomeModal from './WelcomeModal';
import WelcomeBanner from './WelcomeBanner';
import InvalidMembership from './InvalidMembership';
import ReactGA from "react-ga4";
import Banner from './routes/Banner/Banner'
import SupplierOpportunities from './routes/SponsorshipOpportunities/SponsorshipOpportunities';
function App() {
  const { cmpData, loading, welcomeModal, welcomeComplete } = useAuth();
  function isSupplier() {
    if (cmpData.account_info.membershipLevel === "Premier Supplier") {
      return true;
    } else if (cmpData.account_info.membershipLevel === "Supporting Supplier") {
      return true;
    } else if (cmpData.account_info.membershipLevel === "Supplier") {
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    if (!loading) {
      // Initialize Google Analytics.
      ReactGA.initialize("G-B1XKSXMMGB");

      ReactGA.set({
        user_properties: {
          user_role: cmpData.account_info.acgiRole,
          user_memberlevel: cmpData.account_info.membershipLevel,
          user_company: cmpData.account_info.employer,
        },
      });

      if (window.location.host == 'mycues.cues.org') {
        ReactGA.send("pageview");
      }
    }
  }, [loading]);

  return (
    <Layout>
      {!loading && cmpData ? (
        <>

          {cmpData.account_info && cmpData.account_info.membershipLevel ? (
            <>
              {/* Display debugging info for internal/client testing */}
              {/* <DebugInfo /> */}

              {/* Show the welcome modal if user has never logged in. */}
              {/* {welcomeModal && (
                  <WelcomeModal />
                )} */}

              {/* Show the welcome banner if user hasn't finished welcome video. */}
              {/* {!welcomeModal && !welcomeComplete && (
                  <WelcomeBanner />
                )} */}

              {/* Always add account info component before dynamic components. */}
              <AccountInfo />
              {/* {!isSupplier() && (
                <Banner />
              )} */}
              {/* Loop through remaining CMP sections and render in the order they're returned. */}
              {cmpData.cmp_layout && cmpData.cmp_layout.map(sectionId => {
                switch (sectionId) {
                  case "cmp_events": return <Events />;
                  case "cmp_sponsorship_opportunities": return <SupplierOpportunities />
                  case "cmp_cu_management": return <CUManagementSection />;
                  case "cmp_cues_net": return <CuesNet />;
                  case "cmp_benefits": return <Benefits />;
                  case "cmp_my_cues_learning": return <Learning />;
                  default: return <h1>{`Can't find component for section ${sectionId}`}</h1>
                }
              })}
            </>
          ) : (
            <InvalidMembership />
          )}
        </>
      ) : (
        <LoadingScreen />
      )}
    </Layout>
  );
}

export default App;
